import {FC} from 'react';
import LogoutComponent from '../components/LogoutComponent';

interface LogoutPageProps {

}

const LogoutPage: FC<LogoutPageProps> = () => {
    return <LogoutComponent></LogoutComponent>
}

export default LogoutPage;
