import React, {FC, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import {useAuthState} from '../AuthContextProvicer';
import axios from 'axios';
import {config} from '../constants';
import Confetti from 'react-confetti'
import appleStoreLogo from '../assets/images/apple-store.svg';
import {useTranslation} from 'react-i18next';

interface ShareComponentProps {
}


const ShareComponent: FC<ShareComponentProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const authState = useAuthState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [shareSuccess, setShareSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const invitationCode = searchParams.get("invitation-code")

    function acceptShare(invitationCode: string | null) {
        if (invitationCode) {
            axios.put(`${config.API_URL}/share-entry/${invitationCode}`, null).then((result) => {
                setShareSuccess(true);
            }).catch((error) => {
                setShareSuccess(false);
                if(error.response.data.additional_messages[0].description) {
                    setErrorMessage(error.response.data.additional_messages[0].description)
                } else {
                    setErrorMessage('Invitation code is invalid :(')
                }
            })
        }
    }

    return <div className="wrapper-tailwind-compatible flex flex-col items-center justify-center h-screen">
        {shareSuccess && <Confetti recycle={false} numberOfPieces={400}/>}

        <div className="app-logo-container">
            <img src={logo} alt="klingl.me logo" className="app-logo max-w-sm"/>
        </div>
        {!shareSuccess&& <div className="bg-white overflow-hidden shadow rounded-lg p-8 mt-8">
            <h1 className="text-center text-gray-700 text-xl">{t('share.youAreCurrentlySingedInAs')}</h1>
            <p className="text-center text-2xl text-gray-700 mt-2">{authState.user.email}</p>
            <p className="mt-4">{t('share.changeAccountNote')}</p>
            {errorMessage && <p className="text-red-500 mt-5">{errorMessage}</p>}
            <div className="flex flex-col gt-sm:flex-row justify-around mt-4">
                <button type="button" onClick={() => acceptShare(invitationCode)}
                        className="inline-flex items-center justify-center px-6 py-3 m-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    {t('share.acceptShare')}
                </button>
                <button type="button" onClick={() => navigate('/logout')}
                        className="inline-flex items-center justify-center px-6 py-3 m-2 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-700 hover:text-white border-2 border-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    {t('share.logout')}
                </button>
            </div>
            <br/>
        </div> }
        {shareSuccess &&
            <div className="flex flex-col items-center bg-white overflow-hidden shadow rounded-lg p-4 m-8 space-y-2">
                <h1 className="text-center text-gray-700 text-xl font-bold mt-4">{t('share.shareSuccess')}</h1>
                <p>{t('share.yourAreNowPartOfTheKlingl')}</p>
                <p>{t('share.downloadApp')}</p>
                <br/>
                <a href="https://apps.apple.com/us/app/klingl-me/id1526855442">
                    <img src={appleStoreLogo}/>
                </a>
                <br/>
            </div>}
    </div>
}

export default ShareComponent;
