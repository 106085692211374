import axios from 'axios';
import {useAuthState} from 'react-firebase-hooks/auth';
import {fbAuth} from '../firebase-setup';
import {config} from '../constants';

export function AuthHeaderInterceptor() {
    const [user] = useAuthState(fbAuth as any);

    // @ts-ignore
    if (axios.interceptors.request.handlers.length > 0) {
        axios.interceptors.request.eject(0);
    }
    const newAuthInterceptorId = axios.interceptors.request.use(async request => {
        const token = await user?.getIdToken();
        if (request && request.url) {
            const isApiUrl = request.url.startsWith(config.API_URL);
            if (token && isApiUrl) {
                // @ts-ignore
                request.headers.common['X-Authorization-Firebase'] = `${token}`;
            }
        }

        return request;
    });


}
