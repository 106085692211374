import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// TODO in env auslagern
const firebaseConfig = {
    apiKey: "AIzaSyClbbx20MEz2uubYeCWg2HqHe-GTR-R2ao",
    authDomain: "klinglme.firebaseapp.com",
    databaseURL: "https://klinglme.firebaseio.com",
    projectId: "klinglme",
    storageBucket: "klinglme.appspot.com",
    messagingSenderId: "868058019847",
    appId: "1:868058019847:web:069ce1814ed61e3187d2a2",
    measurementId: "G-J1PTNNXYMF"
};

firebase.initializeApp(firebaseConfig);
export const fbAuth = firebase.auth();
