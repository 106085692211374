
export interface Config {
    API_URL: string;
    RING_URL: string;
    IS_DEV: boolean;
}

const dev: Config = {
    API_URL: 'http://localhost:8080',
    RING_URL: 'http://localhost:8080/now',
    IS_DEV: true,
};
const prod: Config = {
    API_URL: 'https://api.klingl.me',
    RING_URL: 'https://app.klingl.me/now',
    IS_DEV: false,
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
