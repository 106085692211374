import {FC} from 'react';
import HomeComponent from '../components/HomeComponent';

interface HomePageProps {

}

const HomePage: FC<HomePageProps> = () => {
    return <HomeComponent></HomeComponent>
}

export default HomePage;
