import React, {FC, VFC} from 'react';
import {useNavigate} from 'react-router-dom';
import {fbAuth} from '../firebase-setup';
import logo from '../assets/images/logo.svg';

interface LogoutComponentProps {
}

const singOut = async () => {
    return await fbAuth.signOut();
}

const LogoutComponent: FC<LogoutComponentProps> = () => {
    const navigate = useNavigate();
    singOut().then((_) => navigate('/login'))

    return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                className="mx-auto h-12 w-auto"
                src={logo}
                alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Logout in progress...</h2>
        </div>
    </div>
}

export default LogoutComponent;
