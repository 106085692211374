import React, {FC, useState} from 'react';
import {KlinglRingResponse} from '../models/KlinglRingResponse.model';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {config} from '../constants';
import logo from '../assets/images/logo.svg';
// @ts-ignore
import SockJsClient from 'react-stomp';

import {useParams, useSearchParams} from 'react-router-dom';
import {StompSessionProvider} from 'react-stomp-hooks';
import RingResponseComponent from './RingResponseComponent';

interface RingComponentProps {
}


const RingComponent: FC<RingComponentProps> = () => {
    const {klinglAlias} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const {t} = useTranslation();
    const [countdown, setCountdown] = React.useState(0);
    const [ringAttemptCounter, setRingAttemptCounter] = React.useState(0);
    const [klinglRingResponse, setKlinglRingResponse] = React.useState({} as KlinglRingResponse);
    const [error, setError] = useState("");
    const [isRinging, setIsRinging] = useState(false);

    const baseSocketUrl = config.API_URL + '/stomp';


    React.useEffect(() => {
        countdown > 0 && setTimeout(() => {
            const newCounterValue = countdown - 1;
            setCountdown(newCounterValue);
            if(newCounterValue <= 0) {
                setIsRinging(false);
            }
        }, 1000);
    }, [countdown]);

    React.useEffect(() => {
        if(klinglAlias) {
            loadKlinglDetails(klinglAlias);
        }
    }, [])

    function doRingAndSaveDetails(ringCorrelator: string = '') {
        let params = {};

        if (ringCorrelator != '') {
            params = {...params, ...{'ring_correlator':  searchParams.get('ringCorrelator')}};
        }
        if(searchParams.get('type')) {
            params = {...params, ...{'type':  searchParams.get('type')}};
        }

        axios.get(`${config.API_URL}/ring/${klinglAlias}`, {params: params})
            .then((res) => {
                setKlinglDetails(res.data);
            })
            .catch((error) => {
                setIsRinging(false);
                setError(error.message);
            });
    }

    function loadKlinglDetails(klinglAlias: string) {
        axios.get(`${config.API_URL}/klingls/alias/${klinglAlias}`)
            .then((res) => {
                const klinglResponse: KlinglRingResponse = res.data;
                setKlinglDetails(klinglResponse);
            }).catch((error) => setError(error.message))
    }

    function setKlinglDetails(klinglRingResponse: KlinglRingResponse) {
        if (klinglRingResponse.ringCorrelator) {
            setSearchParams({ringCorrelator: klinglRingResponse.ringCorrelator});
        }
        setKlinglRingResponse(klinglRingResponse);
        document.title = t('ring') + ' ' + klinglRingResponse.name;
    }

    function ring() {
        setCountdown(15);
        setIsRinging(true);
        setRingAttemptCounter(ringAttemptCounter + 1);
        const ringCorrelator = searchParams.get('ringCorrelator') || '';
        doRingAndSaveDetails(ringCorrelator);
    }

    return (
        <div className="wrapper">
            {isRinging && <h1 className="text-white text-3xl font-bold mb-6">{t('itKlingled')}</h1>}
            <div className="app-logo-container">
                <img src={logo} alt="klingl.me logo" className="app-logo max-w-xs"/>
            </div>
            {!error &&
                <div className="content">
                    <h2 className="text-brandAccent2 text-2xl font-bold mt-6">{klinglRingResponse.name}</h2>

                    <p className="text-white pt-4">
                        {klinglRingResponse.message}</p>
                    <StompSessionProvider
                        url={baseSocketUrl}
                        //All options supported by @stomp/stompjs can be used here
                    >
                        {
                            klinglRingResponse &&
                            <RingResponseComponent ringCorrelator={klinglRingResponse.ringCorrelator}
                                                   klinglId={klinglRingResponse.id}
                                                   attemptId={klinglRingResponse.klinglAttemptId}/>
                        }

                    </StompSessionProvider>
                    {countdown > 0 && <button
                        className="klingl-button-counting-down plain">{t('itRingsXseconds', {seconds: countdown})}</button>}
                    {countdown == 0 &&
                        <div className="klingl-again-button-wrapper">
                           {/* <p className="pb-2">{t('noResponse')}</p>*/}
                            <button className="klingl-button plain"
                                    onClick={() => ring()}>
                                {ringAttemptCounter === 0 ? t('doRing') : t('ringAgain')}
                            </button>
                        </div>
                    }
                </div>
            }

            {/*N O T  A C T I V E  K L I N G L*/}
            {error &&
                <div className="content">
                    <h2 className="text-brandAccent2 text-2xl font-bold mt-6">{t('ringNotActive')}</h2>
                </div>
            }
        </div>
    );
};

export default RingComponent;

