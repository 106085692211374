import {FC} from 'react';
import LoginComponent from '../components/LoginComponent';

interface LoginPageProps {

}

const LoginPage: FC<LoginPageProps> = () => {
    return <LoginComponent></LoginComponent>
}

export default LoginPage;
