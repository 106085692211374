import {FC} from 'react';
import GetQrCodeComponent from '../components/GetQrCodeComponent';

interface GetQrCodePageProps {

}

const GetQrCodePage: FC<GetQrCodePageProps> = () => {
    return <GetQrCodeComponent/>
}

export default GetQrCodePage;
