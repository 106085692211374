import {FC} from 'react';
import ShareComponent from '../components/ShareComponent';

interface SharePageProps {

}

const SharePage: FC<SharePageProps> = () => {
    return <ShareComponent/>
}

export default SharePage;
