import { useState, useEffect, useContext, createContext } from 'react'
import {fbAuth} from './firebase-setup';

const AuthContext = createContext(undefined);

const AuthContextProvider = (props: any) => {
    const [user, setUser] = useState({} as any | null);
    const [error, setError] = useState()

    useEffect(() => {
        const unsubscribe = fbAuth.onAuthStateChanged((resultUser) => {
            if (resultUser) {
               setUser(resultUser);
            } else {
                setUser(null);
            }
        })
        return () => unsubscribe()
    }, [])

    return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
    const auth = useContext(AuthContext)
    // @ts-ignore
    return { ...auth, isAuthenticated: auth.user != null }
}

export default AuthContextProvider;
