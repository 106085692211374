import {FC, useState} from 'react';
import {useSubscription} from 'react-stomp-hooks';
import {KlinglAttemptResponse} from '../models/KlinglAttemptResponse.model';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import {ResponseAction} from '../models/ResponseAction.model';

interface RingResponseComponentProps {
    ringCorrelator: string;
    attemptId: string;
    klinglId: string;
}


const RingResponseComponent: FC<RingResponseComponentProps> = ({ringCorrelator, klinglId,  attemptId}) => {
    const [lastMessage, setLastMessage] = useState({} as KlinglAttemptResponse);
    const {t} = useTranslation();

    //Subscribe to /topic/test, and use handler for all received messages
    //Note that all subscriptions made through the library are automatically removed when their owning component gets unmounted.
    //If the STOMP connection itself is lost they are however restored on reconnect.
    //You can also supply an array as the first parameter, which will subscribe to all destinations in the array
    useSubscription('/topic/ring-correlator/' + ringCorrelator + '/klingl/' + klinglId + '', (message) => setLastMessage(JSON.parse(message.body) as KlinglAttemptResponse));

    return (
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-2">
                <div className="px-4 py-5 sm:px-6">
                    {t('ringAttemptResponse.title')}:
                </div>
                <div className="px-4 py-5 sm:p-6">
                    {lastMessage.responseAction == ResponseAction.ACCEPT &&
                        <div>{t('ringAttemptResponse.accept')}</div>
                    }
                    {lastMessage.responseAction == ResponseAction.REJECT &&
                        <div>{t('ringAttemptResponse.reject')}</div>
                    }
                    <div>{lastMessage.response}</div>
                    {!lastMessage.responseAction &&
                    <p>{t('ringAttemptResponse.noAnswer')}</p>}
                </div>
            </div>


    );

}

export default RingResponseComponent;
