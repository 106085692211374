import React, {FC, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import {config} from '../constants';

interface GetQrCodeComponentProps {

}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
const GetQrCodeComponent: FC<GetQrCodeComponentProps> = () => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const klinglAlias = searchParams.get("alias")
    let klinglRingUrlForQrCode = config.RING_URL + "/" + klinglAlias;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    console.log(windowDimensions)
    function downloadQrCode() {
        const canvas: any = document.querySelector('.HpQrcode > canvas');
        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.download = "klingl-" + klinglAlias + "-QR.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    function getQrCodeSize() {
        const windowWidth = windowDimensions.width - 50; // subtract to create smt like a border
        const resultSize = (windowWidth>512)?512:windowWidth;
        console.log(resultSize);
        return resultSize
    }

    return <div className="wrapper-tailwind-compatible flex flex-col items-center justify-center h-screen">

        <div className="bg-white overflow-hidden shadow rounded-lg p-8 mt-8">
            <h1 className="text-center text-gray-700 text-xl">{t('generateQr.yourKlinglQrCode')}</h1>
            <p className="mt-2">{t('generateQr.stickItNote')}</p>
            <div className="flex flex-row justify-center my-4">
                <QRCodeCanvas   size={getQrCodeSize()}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"H"}  value={klinglRingUrlForQrCode}/>
            </div>
            {/* create 512 pixel sized hidden, used for download image in 512 resolution independent of screen width */}
            <div className="HpQrcode hidden">
                <QRCodeCanvas   size={512}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"H"}  value={klinglRingUrlForQrCode}/>
            </div>
            <div className="flex flex-col gt-sm:flex-row justify-around mt-4">
                <button type="button" onClick={() => downloadQrCode()}
                        className="inline-flex items-center justify-center px-6 py-3 m-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    {t('generateQr.downloadAsPng')}
                </button>
            </div>
            <br/>
        </div>
    </div>
}

export default GetQrCodeComponent;
