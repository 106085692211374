import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import RingPage from './pages/RingPage';
import {AuthHeaderInterceptor} from './interceptors/AuthHeaderInterceptor';
import LogoutPage from './pages/LogoutPage';
import LoginPage from './pages/LoginPage';
import AuthProvider from './AuthContextProvicer';
import {AuthenticatedRoute} from './AuthenticatedRoute';
import SharePage from './pages/SharePage';
import GetQrCodePage from './pages/GetQrCodePage';
import HomePage from './pages/HomePage';

function App() {
    AuthHeaderInterceptor();

    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/now/:klinglAlias" element={<RingPage></RingPage>}/>
                    <Route path="share" element={
                        <AuthenticatedRoute>
                            <SharePage></SharePage>
                        </AuthenticatedRoute>}/>
                    <Route path="tools/generator" element={<GetQrCodePage></GetQrCodePage>}/>
                    <Route path="login" element={<LoginPage></LoginPage>}/>
                    <Route path="logout" element={<LogoutPage></LogoutPage>}/>
                    <Route
                        path="*"
                        element={
                            <HomePage></HomePage>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
