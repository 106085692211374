import {FC} from 'react';
import RingComponent from '../components/RingComponent';

interface RingPageProps {

}

const RingPage: FC<RingPageProps> = () => {
    return <RingComponent></RingComponent>
}

export default RingPage;
